<template>
  <div class="teacher_wrap">
    <div class="info_box">
      <div class="intro_box">
        <div class="name_box">
          <div class="name">{{ teacherItem.name }}</div>
          {{ teacherItem.title2 }}
        </div>

        <div class="intro1">{{ teacherItem.intro1 }}</div>

        <div class="intro2">{{ teacherItem.intro2 }}</div>
      </div>

      <div class="teacher">
        <img :src="teacherItem.img2" />
      </div>
    </div>

    <div class="fw_box fw_box1">
      <div class="title">核心服务</div>

      <div class="list_wrap">
        <div class="list_box list_box1">
          <div class="list">
            <div class="name">策略私享池</div>

            <div class="text">
              融合价值、成长、动量投资策略精髓，精准剖析市场构建资产配置，严谨防控风险，赋能专属投资决策路径
            </div>
          </div>

          <div class="list">
            <div class="name">专案跟踪策略</div>

            <div class="text">
              深度洞察资本市场动态趋势，依托独有的战法模型，精准甄别潜力标的，匠心定制个股跟踪策略
            </div>
          </div>
        </div>

        <div class="list_box list_box2">
          <div class="list">
            <div class="name">VIP策略早鸟报</div>
            
            <div class="text">
              聚焦金融市场前沿，凭借专业方法深度洞察。于市场变化前率先送达，精准剖析宏观经济走势、行业动态及个股机遇，提供独家策略分析与前瞻性投资建议，助力
              VIP 客户抢占先机，在投资浪潮中精准掌舵，尽力为VIP客户服务
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box2">
      <div class="title">会员增值服务</div>

      <div class="list_wrap">
        <div class="list_box">
          <div class="name">上市公司研学</div>

          <div class="text">
            深度聚焦上市公司，专业团队实地走访。深入产业核心区域，与企业高层及一线人员交流互动。精准剖析运营模式、财务状况、市场竞争力，挖掘潜在价值与风险，为投资者、行业研究者提供一手且详实的信息资源与深度洞察成果
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box3">
      <div class="title">恒牛私享家首席《三板斧》战法</div>

      <div class="sub_title">
        核心内容：首板战法、分时买入法、牛回头形态、双响炮应用
      </div>

      <div class="list_wrap">
        <div class="list_box" v-for="(item, index) in course" :key="index">
          <div class="name">{{ item.name }}</div>

          <div class="item_box">
            <div class="item" v-for="(text, index) in item.list" :key="index">
              <img
                :src="require('@/assets/yx_study/new/' + (index + 1) + '.png')"
              />
              <span v-html="text"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_box">
      <div class="btn" @click="$router.push('/vip')">查看更多产品细节</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      course: [
        {
          name: "首板战法",
          list: [
            "首板战法的应用环境",
            "首板战法的理论基础",
            "首板战法的使用方法",
            "首板战法的止盈和止损设置",
          ],
        },
        {
          name: "牛回头战法",
          list: [
            "什么样的模型才是牛回头？",
            "短线为什么要选择牛回头",
            "牛回头的理论体系和使用方法",
            "牛回头的标的如何筛选",
            "牛回头的止盈和止损点的设置",
          ],
        },
        {
          name: "首板战法",
          list: [
            "双响炮战法的使用环境",
            "双响炮战法的理论基础和使用方法",
            "双响炮战法的案例筛选思路",
            "双响炮战法的止盈和止损设",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.teacher_wrap {
  padding-bottom: 80px;

  .info_box {
    background: url("@/assets/yx_study/new/bg1.png") no-repeat;
    background-size: cover;
    padding: 10px 15% 0;
    display: flex;
    justify-content: space-between;

    .intro_box {
      width: 68%;
      color: #fff;
      padding: 8% 0 4%;
      font-size: 16px;

      .name_box {
        display: flex;
        align-items: flex-end;

        .name {
          font-size: 30px;
          font-weight: bold;
          margin-right: 24px;
        }
      }

      .intro1 {
        margin-top: 16px;
      }

      .intro2 {
        margin-top: 26px;
        line-height: 24px;
        text-align: justify;
      }
    }

    .teacher {
      width: 25%;
      min-width: 220px;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .fw_box {
    margin-top: 100px;

    .title {
      font-size: 24px;
      color: #1285ff;
      text-align: center;
    }

    &.fw_box1 {
      .list_wrap {
        padding: 0 15%;
        margin-top: 30px;

        .list_box {
          margin-top: 14px;

          .list {
            padding: 30px;
            box-sizing: border-box;
            border-radius: 20px;

            .name {
              font-size: 20px;
              font-weight: 700;
            }

            .text {
              margin-top: 16px;
              font-size: 14px;
              line-height: 24px;
            }
          }

          &.list_box1 {
            display: flex;
            justify-content: space-between;

            .list {
              width: calc((100% - 14px) / 2);
              background: url("@/assets/yx_study/new/fw2.png") no-repeat;
              background-size: cover;
            }
          }

          &.list_box2 {
            .list {
              background: url("@/assets/yx_study/new/fw1.png") no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }

    &.fw_box2 {
      background: url("@/assets/yx_study/new/bg2.png") no-repeat;
      background-size: cover;
      padding: 80px 15%;

      .list_wrap {
        color: #fff;

        .list_box {
          margin-top: 30px;

          .name {
            font-size: 20px;
            font-weight: 700;
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    &.fw_box3 {
      .sub_title {
        margin-top: 16px;
        font-size: 18px;
        text-align: center;
      }

      .list_wrap {
        padding: 0 15%;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .list_box {
          width: calc((100% - 12px * 2) / 3);
          display: flex;
          flex-direction: column;

          .name {
            background: url("@/assets/yx_study/new/zf3.png");
            background-size: 100% 100%;
            height: 56px;
            line-height: 56px;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
          }

          .item_box {
            flex-grow: 1;
            margin-top: 8px;
            background: #f0f4f8;
            border-radius: 8px;
            padding: 12px;

            .item {
              display: flex;
              font-size: 14px;
              background: #fff;
              margin-top: 10px;
              padding: 10px 14px;
              border-radius: 4px;
              line-height: 24px;

              &:first-child {
                margin-top: 0;
              }

              img {
                width: 18px;
                height: 18px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .btn {
      background: linear-gradient(92deg, #1285ff 0%, #2cdbff 100%);
      border-radius: 6px;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>