<template>
  <div class="footer_wrap">
    <div class="footer_list">
      <div class="footer_item about_item">
        <div class="item_title">关于我们</div>

        <div class="item_box about_box">
          <div
            class="text about_text"
            v-for="(item, index) in about_arr"
            :key="index"
          >
            <a target="_blank" :href="item.link">{{ item.name }}</a>
          </div>
        </div>
      </div>

      <div class="footer_item gzh_item">
        <div class="cont">
          <div class="item_title">关注我们</div>

          <div class="item_box gzh_box">
            <div class="text gzh_text">
              <img src="@/assets/footer/icon1.png" class="icon" />
              益学微信公众号：
            </div>

            <img src="@/assets/footer/wxgzh.png" class="wxgzh_ewm" />
          </div>
        </div>
      </div>

      <div class="footer_item fwrx_item">
        <div class="cont">
          <div class="item_title">服务热线</div>

          <div class="item_box fwrx_box">
            <div class="text fwrx_text">
              销售服务专线：
              <span>400-673-6018</span>
            </div>
            <div class="text fwrx_text">
              VIP服务专线：
              <span>400-673-6019</span>
            </div>
            <div class="text fwrx_text">
              益学法律事务联系电话：
              <span>021-6084-0777</span>
            </div>
          </div>
        </div>
      </div>

      <div class="footer_item tsjy_item">
        <div class="cont">
          <div class="item_title">投诉建议</div>

          <div class="item_box tsjy_box">
            <div class="text tsjy_text">
              <img src="@/assets/footer/icon2.png" class="icon" />
              电话：
              <span>400-673-6018</span>
            </div>

            <div class="text tsjy_text">
              <img src="@/assets/footer/icon3.png" class="icon" />
              邮箱：
              <span>ts@yxcps.com</span>
            </div>

            <div class="text tsjy_text">
              <img src="@/assets/footer/icon4.png" class="icon" />
              QQ：
              <span>2881075048</span>
            </div>

            <div class="text tsjy_text tsjy_text1">
              <img src="@/assets/footer/icon5.png" class="icon" />
              微信：
              <span><img src="@/assets/footer/wx.png" class="wx_ewm" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="friend_links">
      <div class="friend_title">友情链接：</div>

      <div class="links">
        <a target="_blank" href="http://finance.qq.com">腾讯财经</a>
        <a target="_blank" href="http://www.cs.ecitic.com/">中信证券</a>
        <a target="_blank" href="https://www.alipay.com/">支付宝</a>
        <a target="_blank" href="http://finance.sina.com.cn/">新浪财经</a>
        <a target="_blank" href="http://www.bankofbeijing.com.cn/">北京银行</a>
        <a target="_blank" href="http://www.360.cn/">360安全卫士</a>
        <a target="_blank" href="http://www.gjzq.com.cn">国金证券</a>
        <a target="_blank" href="http://www.gyzq.com.cn">国元证券</a>
        <a target="_blank" href="http://www.ghzq.com.cn">国海证券</a>
        <a target="_blank" href="http://www.westsecu.com">西部证券</a>
        <a target="_blank" href="http://www.cifco996.com">中国中期</a>
        <a target="_blank" href="http://www.foundersc.com">方正证券</a>
        <a target="_blank" href="http://www.nesc.cn">东北证券</a>
        <a target="_blank" href="http://www.95579.com">长江证券</a>
        <a target="_blank" href="http://www.htsec.com">海通证券</a>
        <a target="_blank" href="http://www.tpyzq.com">太平洋</a>
        <a target="_blank" href="http://www.swsc.com.cn">西南证券</a>
        <a target="_blank" href="http://www.guosen.com.cn">国信证券</a>
        <a target="_blank" href="http://www.dwjq.com.cn">东吴证券</a>
        <a target="_blank" href="http://www.xyzq.com.cn">兴业证券</a>
        <a target="_blank" href="http://www.newone.com.cn">招商证券</a>
        <a target="_blank" href="http://www.gf.com.cn">广发证券</a>
        <a target="_blank" href="http://www.dxzq.net">东兴证券</a>
        <a target="_blank" href="http://www.i618.com.cn">山西证券</a>
        <a target="_blank" href="http://www.ajzq.com">爱建证券有限责任公司</a>
        <a target="_blank" href="http://www.essence.com.cn">
          安信证券股份有限公司
        </a>
        <a target="_blank" href="http://www.cgws.com">长城证券有限责任公司</a>
        <a target="_blank" href="http://www.cjsc.com">长江证券股份有限公司</a>
        <a target="_blank" href="http://www.longone.com.cn">
          东海证券有限责任公司
        </a>
        <a target="_blank" href="http://www.ebscn.com">光大证券股份有限公司</a>
        <a target="_blank" href="http://www.hazq.com">华安证券有限责任公司</a>
        <a target="_blank" href="http://www.lhzq.cn">
          华泰联合证券有限责任公司
        </a>
        <a target="_blank" href="http://www.Htsc.com.cn">
          华泰证券股份有限公司
        </a>
      </div>
    </div>

    <div class="about_links">
      <a target="_blank" href="//www.yxcps.com/aboutUs"> 关于我们 </a>
      <span class="sep">|</span>
      <a target="_blank" href="//www.yxcps.com/aboutUs?tabId=2"> 联系我们 </a>
      <span class="sep">|</span>
      <a target="_blank" href="//www.yxcps.com/joinUs"> 加入我们 </a>
      <span class="sep">|</span>
      <a target="_blank" href="//www.yxcps.com/aboutUs?tabId=2&subTabId=3">
        益学声明
      </a>
      <span class="sep">|</span>
      <a target="_blank" href="//www.yxcps.com/aboutUs?tabId=2&subTabId=3">
        免责声明
      </a>
    </div>

    <div class="zjh_box">
      <img src="@/assets/footer/zjh.png" />

      <div class="zjh_text">
        <b>
          经营证券期货业务许可证 统一社会信用代码
          <br />
          （境外机构编号）：91310109MA1G5BK839
          <br />
        </b>
        提醒广大投资者选择正规合法的证券投资咨询机构提供服务
      </div>
    </div>

    <div class="icp_box">
      <div class="icp1">
        Copyright 2014-2025 上海益学智迅科技发展有限公司 All rights reserved.
        <a target="_blank" href="https://beian.miit.gov.cn/">
          沪ICP备17040656号-9
        </a>
      </div>

      <div class="icp2">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402002760"
        >
          <img src="@/assets/footer/icp.png" />沪公网安备31010402002760号
        </a>
      </div>
    </div>

    <div class="tips_box">
      <div class="tips">
        投资有风险，入市先学习 机构名单可在中国证监会/中国证券业协会网站查询
      </div>

      <div class="tips">
        亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。
      </div>

      <div class="tips_img">
        <a
          target="_blank"
          href="http://www.315online.com.cn/member/315150018.html"
        >
          <img src="@/assets/footer/315new.png" />
        </a>

        <img src="@/assets/footer/962110.png" />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      about_arr: [
        {
          name: "公司介绍",
          link: "//www.yxcps.com/aboutUs",
        },
        {
          name: "免责声明",
          link: "//www.yxcps.com/aboutUs?tabId=2&subTabId=3",
        },
        {
          name: "益学公益",
          link: "//www.yxcps.com/aboutUs?tabId=1&subTabId=3",
        },
        {
          name: "从业人员信息公示",
          link: "//www.yxcps.com/aboutUs?tabId=0&subTabId=6",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
  
<style lang="less" scoped>
.footer_wrap {
  height: 100%;
  background: #171a1d;
  padding: 110px 10% 100px;
  box-sizing: border-box;
  font-size: 12px;
  color: #bbb;

  a {
    text-decoration: none;
    color: #bbb;
  }

  .footer_list {
    color: #fff;
    display: flex;

    .footer_item {
      width: 25%;
      box-sizing: border-box;
      border-left: 1px solid #2f3134;

      &:first-child {
        border-left: 0;
      }

      .item_title {
        font-size: 16px;
      }

      .item_box {
        padding-top: 30px;

        .text {
          color: #bbb;

          &.about_text {
            a {
              display: inline-block;
              margin-bottom: 20px;

              &:hover {
                color: #5dacff;
              }
            }
          }

          .icon {
            width: 12px;
            display: block;
            margin-right: 8px;
          }

          span {
            color: #fff;
          }

          &.gzh_text,
          &.fwrx_text,
          &.tsjy_text {
            display: flex;
            align-items: center;
          }
        }
      }

      &.about_item {
        width: 15%;
      }

      &.gzh_item {
        width: 30%;
        display: flex;
        justify-content: center;

        .cont {
          .gzh_box {
            .wxgzh_ewm {
              width: 120px;
              display: block;
              margin-top: 50px;
              // margin-left: 20px;
            }
          }
        }
      }

      &.fwrx_item {
        width: 35%;
        display: flex;
        justify-content: center;

        .fwrx_box {
          .fwrx_text {
            margin-bottom: 20px;
          }
        }
      }

      &.tsjy_item {
        width: 20%;
        display: flex;
        justify-content: flex-end;

        .tsjy_box {
          .tsjy_text {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            &.tsjy_text1 {
              align-items: flex-start;

              .wx_ewm {
                width: 120px;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .friend_links {
    margin-top: 50px;
    display: flex;
    line-height: 24px;
    padding-bottom: 14px;
    border-bottom: 1px solid #2f3134;

    .friend_title {
      width: 70px;
    }

    .links {
      width: calc(100% - 70px);
      text-align: justify;

      a {
        margin-right: 12px;
        margin-bottom: 10px;

        &:hover {
          color: #5dacff;
        }
      }
    }
  }

  .about_links {
    margin-top: 18px;
    text-align: center;

    a {
      margin: 0 30px;

      &:hover {
        color: #5dacff;
      }
    }

    .sep {
      color: #2f3134;
      cursor: default;
    }
  }

  .zjh_box {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      display: block;
    }

    .zjh_text {
      text-align: center;
      line-height: 20px;
      margin-left: 28px;
    }
  }

  .icp_box {
    margin-top: 24px;

    .icp1 {
      text-align: center;

      a {
        padding-left: 12px;
      }
    }

    .icp2 {
      margin-top: 8px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-right: 6px;
        }
      }
    }
  }

  .tips_box {
    margin-top: 24px;
    text-align: center;

    .tips {
      margin-top: 8px;
    }

    .tips_img {
      margin-top: 18px;

      a {
        margin-right: 20px;
      }
    }
  }
}

@media (min-width: 1921px) {
  .footer_wrap {
    font-size: 18px;

    .footer_list {
      .footer_item {
        .item_title {
          font-size: 24px;
        }
      }
    }

    .friend_links {
      margin-top: 100px;
      padding-bottom: 28px;
      line-height: 32px;

      .friend_title {
        width: 100px;
      }

      .links {
        width: calc(100% - 100px);
      }
    }

    .about_links {
      margin-top: 36px;
    }

    .zjh_box {
      margin-top: 60px;

      img {
        width: 60px;
      }

      .zjh_text {
        line-height: 24px;
      }
    }

    .icp_box {
      margin-top: 48px;

      .icp2 {
        margin-top: 16px;
      }
    }

    .tips_box {
      margin-top: 48px;

      .tips {
        margin-top: 16px;
      }

      .tips_img {
        margin-top: 36px;
      }
    }
  }
}
</style>