<template>
  <div class="teacher_wrap">
    <div class="info_box">
      <div class="intro_box">
        <div class="name_box">
          <div class="name">{{ teacherItem.name }}</div>
          {{ teacherItem.title2 }}
        </div>

        <div class="intro1">{{ teacherItem.intro1 }}</div>

        <div class="intro2">{{ teacherItem.intro2 }}</div>
      </div>

      <div class="teacher">
        <img :src="teacherItem.img2" />
      </div>
    </div>

    <div class="fw_box fw_box1">
      <div class="title">核心服务</div>

      <div class="list_wrap">
        <div class="list_box list_box1">
          <div class="list">
            <div class="name">教研标的</div>

            <div class="text">
              精选优质股票池，以研究为驱动，以结果为导向，好方法+好工具+好策略。结合基本面、技术面、资金面。追踪主流热点，挖掘价值成本标的
            </div>
          </div>

          <div class="list">
            <div class="name">盘口看盘</div>

            <div class="text">
              盘前，盘面，盘后的全方位服务，在各个时间段，给出自身根据盘面得出的论点给予到用户进行研究，快速把握机会。在分秒中得出盘面结论
            </div>
          </div>
        </div>

        <div class="list_box list_box2">
          <div class="list">
            <div class="name">量价战法课</div>

            <div class="text">洞察主力动向与主力共舞</div>
          </div>

          <div class="list">
            <div class="name">坐庄训练营</div>

            <div class="text">跟庄研究实用战法揭秘系列</div>
          </div>

          <div class="list">
            <div class="name">道与术</div>

            <div class="text">交易之道，当先治心，交易模式思考</div>
          </div>

          <div class="list">
            <div class="name">基础必学</div>

            <div class="text">股市交易基础知识的学习</div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box2">
      <div class="title">投教服务</div>

      <div class="list_wrap">
        <div class="list_box">
          <div class="name">投资是一场修行系列</div>
          <div class="text">
            深入探讨人性在投资中的作用，如贪婪和恐惧如何影响投资决策，以及如何克服这些心理因素。通过心理学原理和案例分析，帮助学员更好地认识自己的情绪和行为模式，从而在投资中保持冷静和理智
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box3">
      <div class="title">量价结构战法</div>

      <div class="sub_title">
        核心内容:量价结构,筹码破绽,机构行为,十大操盘密码,盘口强弱,机构T+0战法
      </div>

      <div class="list_wrap list_wrap1">
        <div class="zf_title">体系课程</div>

        <div class="zf_list">
          <div class="item_box">
            <div class="item" v-for="(text, index) in course1" :key="index">
              <img
                :src="require('@/assets/yx_study/new/' + (index + 1) + '.png')"
              />
              <span v-html="text"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="list_wrap list_wrap2">
        <div class="zf_list">
          <div class="list_box" v-for="(item, index) in course2" :key="index">
            <div class="name">{{ item.name }}</div>

            <div class="item_box">
              <div class="item" v-for="(text, index) in item.list" :key="index">
                <img
                  :src="
                    require('@/assets/yx_study/new/' + (index + 1) + '.png')
                  "
                />
                <span v-html="text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list_wrap list_wrap3">
        <div class="zf_title">指标课程</div>

        <div class="zf_list">
          <div class="list_box" v-for="(item, index) in course3" :key="index">
            <div class="item_box">
              <div class="item" v-for="(text, index) in item" :key="index">
                <img
                  :src="
                    require('@/assets/yx_study/new/' + (index + 1) + '.png')
                  "
                />
                <span v-html="text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_box">
      <div class="btn" @click="$router.push('/vip')">查看更多产品细节</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      course1: [
        "量价体系",
        "个股分时盘口强势辨析",
        "重新认识K线本质",
        "探秘筹码，解读量价关系",
        "成交量隐藏的秘密",
        "超级筹码分布和流程机理",
        "K线组合快速入门",
        "私募级超级移动筹码",
        "K线形态的种类",
        "私募级量价结构篇",
        "多个指标战法详解",
        "静态案例教学-量价结构篇",
        "IPO打新的诀窍",
        "静态案例教学-筹码篇",
      ],
      course2: [
        {
          name: "投资理念",
          list: [
            "对市场的理解",
            "对投资的理解",
            "对仓位的理解",
            "对选股的理解",
            "对股价波动的理解",
          ],
        },
        {
          name: "选股技巧",
          list: [
            "海豚张嘴实战选股技巧",
            "金鸡报晓实战选股技巧",
            "金三角实战选股技巧",
            "美人躺实战选股技巧",
            "大盘盘口强弱辨析",
          ],
        },
      ],
      course3: [
        [
          "【买】确定方向 主动买跌",
          "【买】金三角回踩五日均线",
          "【买】上涨回调对等缩量",
          "【买】海豚张嘴 MACD共振",
          "【买】趋势通道下轨支撑",
          "【买】对称收复反包追涨",
        ],
        [
          "【卖】常规型态 高位巨阴",
          "【卖】强势股之堆量滞涨反跌",
          "【卖】次新股之开板堆量不涨",
          "【卖】妖股放量后不涨与再涨",
        ],
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.teacher_wrap {
  padding-bottom: 80px;

  .info_box {
    background: url("@/assets/yx_study/new/bg1.png") no-repeat;
    background-size: cover;
    padding: 10px 15% 0;
    display: flex;
    justify-content: space-between;

    .intro_box {
      width: 68%;
      color: #fff;
      padding: 8% 0 4%;
      font-size: 16px;

      .name_box {
        display: flex;
        align-items: flex-end;

        .name {
          font-size: 30px;
          font-weight: bold;
          margin-right: 24px;
        }
      }

      .intro1 {
        margin-top: 16px;
      }

      .intro2 {
        margin-top: 26px;
        line-height: 24px;
        text-align: justify;
      }
    }

    .teacher {
      width: 25%;
      min-width: 220px;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .fw_box {
    margin-top: 100px;

    .title {
      font-size: 24px;
      color: #1285ff;
      text-align: center;
    }

    &.fw_box1 {
      .list_wrap {
        padding: 0 15%;
        margin-top: 30px;

        .list_box {
          margin-top: 14px;
          display: flex;
          justify-content: space-between;

          .list {
            padding: 30px;
            box-sizing: border-box;
            border-radius: 20px;

            .name {
              font-size: 20px;
              font-weight: 700;
            }

            .text {
              margin-top: 16px;
              font-size: 14px;
              line-height: 24px;
            }
          }

          &.list_box1 {
            .list {
              width: calc((100% - 14px) / 2);
              background: url("@/assets/yx_study/new/fw2.png") no-repeat;
              background-size: cover;
            }
          }

          &.list_box2 {
            .list {
              width: calc((100% - 14px * 3) / 4);
              background: url("@/assets/yx_study/new/fw3.png") no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }

    &.fw_box2 {
      background: url("@/assets/yx_study/new/bg2.png") no-repeat;
      background-size: cover;
      padding: 80px 15%;

      .list_wrap {
        color: #fff;

        .list_box {
          margin-top: 30px;

          .name {
            font-size: 20px;
            font-weight: 700;
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    &.fw_box3 {
      .sub_title {
        margin-top: 16px;
        font-size: 18px;
        text-align: center;
      }

      .list_wrap {
        padding: 0 15%;

        .zf_title {
          background: url("@/assets/yx_study/new/zf1.png");
          background-size: 100% 100%;
          font-size: 18px;
          font-weight: 700;
          height: 56px;
          line-height: 56px;
          text-align: center;
        }

        &.list_wrap1 {
          margin-top: 40px;

          .zf_list {
            margin-top: 8px;

            .item_box {
              background: #f0f4f8;
              border-radius: 8px;
              padding: 12px;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 10px;

              .item {
                width: calc((100% - 12px) / 2);
                display: flex;
                align-items: center;
                font-size: 14px;
                background: #fff;
                padding: 10px 14px;
                box-sizing: border-box;
                border-radius: 4px;
                line-height: 24px;

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        &.list_wrap2 {
          margin-top: 12px;

          .zf_list {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 10px;

            .list_box {
              width: calc((100% - 12px) / 2);
              display: flex;
              flex-direction: column;
              font-size: 14px;

              .name {
                background: url("@/assets/yx_study/new/zf2.png");
                background-size: 100% 100%;
                height: 56px;
                line-height: 56px;
                text-align: center;
                // font-size: 18px;
                font-weight: 700;
              }

              .item_box {
                flex-grow: 1;
                margin-top: 8px;
                background: #f0f4f8;
                border-radius: 8px;
                padding: 12px;
                display: flex;
                flex-direction: column;

                .item {
                  flex-grow: 1;
                  display: flex;
                  align-items: center;
                  // font-size: 14px;
                  background: #fff;
                  margin-top: 10px;
                  padding: 10px 14px;
                  border-radius: 4px;
                  line-height: 24px;

                  &:first-child {
                    margin-top: 0;
                  }

                  img {
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }

        &.list_wrap3 {
          margin-top: 12px;

          .zf_list {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            background: #f0f4f8;
            padding: 12px;
            border-radius: 8px;

            .list_box {
              width: calc((100% - 12px) / 2);
              display: flex;
              flex-direction: column;
              font-size: 14px;

              .item_box {
                flex-grow: 1;
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                .item {
                  display: flex;
                  align-items: center;
                  // font-size: 14px;
                  background: #fff;
                  margin-top: 10px;
                  padding: 10px 14px;
                  border-radius: 4px;
                  line-height: 24px;

                  &:first-child {
                    margin-top: 0;
                  }

                  img {
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .btn {
      background: linear-gradient(92deg, #1285ff 0%, #2cdbff 100%);
      border-radius: 6px;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>