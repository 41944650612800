<template>
  <div class="teacher_wrap">
    <div class="info_box">
      <div class="intro_box">
        <div class="name_box">
          <div class="name">{{ teacherItem.name }}</div>
          {{ teacherItem.title2 }}
        </div>

        <div class="intro1">{{ teacherItem.intro1 }}</div>

        <div class="intro2">{{ teacherItem.intro2 }}</div>
      </div>

      <div class="teacher">
        <img :src="teacherItem.img2" />
      </div>
    </div>

    <div class="fw_box fw_box1">
      <div class="title">交易模型</div>

      <div class="list_wrap">
        <div class="list_box">
          <div class="list">
            <div class="name">仓位管理</div>

            <div class="item_box">
              <div class="item">标准普尔家庭资产配置模型</div>
              <div class="item">倒金子塔加减仓法</div>
            </div>
          </div>

          <div class="list">
            <div class="name">卖出法则</div>

            <div class="item_box">
              <div class="item">中线趋势破位卖出法</div>
              <div class="item">中线出货卖出法</div>
              <div class="item">中线估值上限卖出法</div>
            </div>
          </div>

          <div class="list">
            <div class="name">心态养成</div>

            <div class="item_box">
              <div class="item">真知行不难</div>
              <div class="item">纪律刚性第一原则</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box2">
      <div class="title">核心服务</div>

      <div class="list_wrap">
        <div class="list_box">
          <div class="name">六势择时</div>
          <div class="text">
            破势-下跌期--严控仓位；酿势-止跌期--震荡蓄势；转势-转折期--蓄势待发；升势-主升期--上涨开启；裂势-缓涨期--分歧加大；颓势-滞涨期-兑现出货
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box3">
      <div class="title">六势操盘战法</div>

      <div class="sub_title">
        核心内容:趋势判断,超短线情绪博弈战法,中线波段操盘战法,三七估值选股,筹码锁仓解密,事件驱动预期差选股
      </div>

      <div class="list_wrap">
        <div class="zf_title">四维选股</div>

        <div class="zf_list">
          <div class="list_box" v-for="(item, index) in course" :key="index">
            <div class="name">{{ item.name }}</div>

            <div class="item_box">
              <div class="item" v-for="(text, index) in item.list" :key="index">
                <img
                  :src="
                    require('@/assets/yx_study/new/' + (index + 1) + '.png')
                  "
                />
                <span v-html="text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_box">
      <div class="btn" @click="$router.push('/vip')">查看更多产品细节</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      course: [
        {
          name: "短线情绪博弈",
          list: [
            "情绪爆点选股法",
            "情绪分歧低吸选股法",
            "黄金坑短线选股法",
            "筹码锁仓选股法",
          ],
        },
        {
          name: "中线波段选股法",
          list: [
            "酝势趋势选股法",
            "筹码持仓度分析选股法",
            "缺口断层选股法",
            "事件驱动预期差选殷",
          ],
        },
        {
          name: "价值长线选股",
          list: [
            "行业龙头估值分析法<br>(PEG选股模型)",
            "成长股的价值分析法<br>(三七估值选股)",
          ],
        },
        {
          name: "成分股套利选股法",
          list: ["宽基指数成分股套利", "ETF指数套利"],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.teacher_wrap {
  padding-bottom: 80px;

  .info_box {
    background: url("@/assets/yx_study/new/bg1.png") no-repeat;
    background-size: cover;
    padding: 10px 15% 0;
    display: flex;
    justify-content: space-between;

    .intro_box {
      width: 68%;
      color: #fff;
      padding: 8% 0 4%;
      font-size: 16px;

      .name_box {
        display: flex;
        align-items: flex-end;

        .name {
          font-size: 30px;
          font-weight: bold;
          margin-right: 24px;
        }
      }

      .intro1 {
        margin-top: 16px;
      }

      .intro2 {
        margin-top: 26px;
        line-height: 24px;
        text-align: justify;
      }
    }

    .teacher {
      width: 25%;
      min-width: 220px;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .fw_box {
    margin-top: 100px;

    .title {
      font-size: 24px;
      color: #1285ff;
      text-align: center;
    }

    &.fw_box1 {
      .list_wrap {
        padding: 0 15%;
        margin-top: 30px;

        .list_box {
          margin-top: 14px;
          display: flex;
          justify-content: space-between;

          .list {
            width: calc((100% - 14px * 2) / 3);
            padding: 30px;
            box-sizing: border-box;
            border-radius: 20px;
            background: url("@/assets/yx_study/new/fw3.png") no-repeat;
            background-size: cover;

            .name {
              font-size: 20px;
              font-weight: 700;
            }

            .item_box {
              margin-top: 16px;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
    }

    &.fw_box2 {
      background: url("@/assets/yx_study/new/bg2.png") no-repeat;
      background-size: cover;
      padding: 80px 15%;

      .list_wrap {
        color: #fff;

        .list_box {
          margin-top: 30px;

          .name {
            font-size: 20px;
            font-weight: 700;
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    &.fw_box3 {
      .sub_title {
        margin-top: 16px;
        font-size: 18px;
        text-align: center;
      }

      .list_wrap {
        padding: 0 15%;
        margin-top: 40px;

        .zf_title {
          background: url("@/assets/yx_study/new/zf1.png");
          background-size: 100% 100%;
          font-size: 18px;
          font-weight: 700;
          color: #1285ff;
          height: 56px;
          line-height: 56px;
          text-align: center;
        }

        .zf_list {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: stretch;

          .list_box {
            width: calc((100% - 12px * 3) / 4);
            display: flex;
            flex-direction: column;
            font-size: 14px;

            .name {
              background: url("@/assets/yx_study/new/zf3.png");
              background-size: 100% 100%;
              height: 56px;
              line-height: 56px;
              text-align: center;
              // font-size: 18px;
              font-weight: 700;
            }

            .item_box {
              flex-grow: 1;
              margin-top: 8px;
              background: #f0f4f8;
              border-radius: 8px;
              padding: 12px;
              display: flex;
              flex-direction: column;

              .item {
                flex-grow: 1;
                display: flex;
                align-items: center;
                // font-size: 14px;
                background: #fff;
                margin-top: 10px;
                padding: 10px 14px;
                border-radius: 4px;
                line-height: 24px;

                &:first-child {
                  margin-top: 0;
                }

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .btn {
      background: linear-gradient(92deg, #1285ff 0%, #2cdbff 100%);
      border-radius: 6px;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>