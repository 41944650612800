<template>
  <div class="teacher_wrap">
    <div class="info_box">
      <div class="intro_box">
        <div class="name_box">
          <div class="name">{{ teacherItem.name }}</div>
          {{ teacherItem.title2 }}
        </div>

        <div class="intro1">{{ teacherItem.intro1 }}</div>

        <div class="intro2">{{ teacherItem.intro2 }}</div>
      </div>

      <div class="teacher">
        <img :src="teacherItem.img2" />
      </div>
    </div>

    <div class="fw_box fw_box1">
      <div class="title">核心服务</div>

      <div class="list_wrap">
        <div class="list_box list_box1">
          <div class="list">
            <div class="name">教研案例分析</div>

            <div class="text">
              每日将收集不同的教学案例、作业标的进行集中分析，更加清晰地指导学员实现自我学习完善
            </div>
          </div>

          <div class="list">
            <div class="name">盘中互动</div>

            <div class="text">
              盘中文字直播，学员可以时时互动，更会针对性的答疑学员问题，当天集合显示老师所有观点
            </div>
          </div>
        </div>

        <div class="list_box list_box2">
          <div class="list">
            <div class="name">专享助理服务</div>

            <div class="text">
              杨凯老师所带领的专业团队，会精心为您安排专属助理。专属助理将全程贴心陪伴，针对您在学习过程中遇到的各类疑问，进行详尽且专业的解答，为您拨开重重迷雾。同时，助理还会全力辅助您开展课程学习，让您的学习之旅更加顺畅、高效，轻松掌握课程知识要点
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box2">
      <div class="title">精髓要义</div>

      <div class="list_wrap">
        <div class="list_box">
          <div class="name">深度解盘、特刊荟萃</div>
          <div class="text">
            早盘直击开启市场洞察，十点看盘精准研判走势，盘中速递传递即时讯息，收评解析总结全天概况。不定期更新，以图文形式的行业特刊或个股特刊，对热门标的进行独家深度解析
          </div>
        </div>
      </div>
    </div>

    <div class="fw_box fw_box3">
      <div class="title">机构操盘战法</div>

      <div class="sub_title">
        主要内容:机构操盘线,机构动能线,机构筹码流动,机构持仓量,机构操盘步骤,机构操盘破绽
      </div>

      <div class="list_wrap list_wrap1">
        <div class="zf_title">战法入门篇</div>

        <div class="zf_list">
          <div class="item_box">
            <div class="item" v-for="(text, index) in course1" :key="index">
              <img
                :src="require('@/assets/yx_study/new/' + (index + 1) + '.png')"
              />
              <span v-html="text"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="list_wrap list_wrap2">
        <div class="zf_title">高级战法课</div>

        <div class="zf_list">
          <div class="list_box" v-for="(item, index) in course2" :key="index">
            <div class="name">{{ item.name }}</div>

            <div class="item_box">
              <div class="item" v-for="(text, index) in item.list" :key="index">
                <img
                  :src="
                    require('@/assets/yx_study/new/' + (index + 1) + '.png')
                  "
                />
                <span v-html="text"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_box">
      <div class="btn" @click="$router.push('/vip')">查看更多产品细节</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teacherItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      course1: [
        "浪型解析（上涨浪、下跌浪）",
        "市场环境（震荡期、上涨期、下跌期）",
        "建仓形态（横盘建仓、箱体建仓）",
        "箱体震荡、建仓选时",
        "拉升形态",
        "出货形态（2种出货方式）",
        "上量形态（3种上量及下量）",
        "黄金分割线（基本画线、支撑位、压力位）",
        "板块效应",
        "短线股与中线股的区分",
        "回调完毕买入法",
        "最佳盘口（高开、低开、平开）",
      ],
      course2: [
        {
          name: "第一章 机构操盘线",
          list: [
            "机构操盘线画法",
            "机构操盘线短线买入操作技巧",
            "机构操盘线中线买入操作技巧",
            "机构操盘线判定市场“选时”位技巧",
          ],
        },
        {
          name: "第二章 机构动能线",
          list: [
            "机构动能线判定技巧",
            "机构短线2次建仓完毕模式",
            "机构中线3次建仓完毕模式",
            "短线激进买点操作技巧",
          ],
        },
        {
          name: "第三章 机构筹码流动",
          list: [
            "机构筹码拉升，洗盘，出货模式",
            "机构筹码加仓，补仓模式",
            "机构筹码换庄，快速加仓模式",
            "机构筹码判定市场建仓区间技巧",
          ],
        },
        {
          name: "第四章 机构持仓量",
          list: [
            "识别机构建仓平台",
            "机构持仓量及控盘度",
            "判定黑马股，白马股技巧",
            "中线机构持仓量个股选股及操作技巧",
          ],
        },
        {
          name: "第五章 机构操盘步骤",
          list: [
            "机构操盘步骤识别",
            "机构弱市期反弹操盘技巧",
            "机构强市期非2元次操盘技巧",
            "中线机构操盘步骤个股选股及操作技巧",
          ],
        },
        {
          name: "第六章 机构操盘破绽",
          list: [
            "机构操盘中的拉升破绽",
            "机构操盘中的洗盘破绽",
            "机构操盘中的出货破绽",
            "机构操盘中的底部启动破绽",
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.teacher_wrap {
  padding-bottom: 80px;

  .info_box {
    background: url("@/assets/yx_study/new/bg1.png") no-repeat;
    background-size: cover;
    padding: 10px 15% 0;
    display: flex;
    justify-content: space-between;

    .intro_box {
      width: 68%;
      color: #fff;
      padding: 8% 0 4%;
      font-size: 16px;

      .name_box {
        display: flex;
        align-items: flex-end;

        .name {
          font-size: 30px;
          font-weight: bold;
          margin-right: 24px;
        }
      }

      .intro1 {
        margin-top: 16px;
      }

      .intro2 {
        margin-top: 26px;
        line-height: 24px;
        text-align: justify;
      }
    }

    .teacher {
      width: 25%;
      min-width: 220px;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .fw_box {
    margin-top: 100px;

    .title {
      font-size: 24px;
      color: #1285ff;
      text-align: center;
    }

    &.fw_box1 {
      .list_wrap {
        padding: 0 15%;
        margin-top: 30px;

        .list_box {
          margin-top: 14px;

          .list {
            padding: 30px;
            box-sizing: border-box;
            border-radius: 20px;

            .name {
              font-size: 20px;
              font-weight: 700;
            }

            .text {
              margin-top: 16px;
              font-size: 14px;
              line-height: 24px;
            }
          }

          &.list_box1 {
            display: flex;
            justify-content: space-between;

            .list {
              width: calc((100% - 14px) / 2);
              background: url("@/assets/yx_study/new/fw2.png") no-repeat;
              background-size: cover;
            }
          }

          &.list_box2 {
            .list {
              background: url("@/assets/yx_study/new/fw1.png") no-repeat;
              background-size: cover;
            }
          }
        }
      }
    }

    &.fw_box2 {
      background: url("@/assets/yx_study/new/bg2.png") no-repeat;
      background-size: cover;
      padding: 80px 15%;

      .list_wrap {
        color: #fff;

        .list_box {
          margin-top: 30px;

          .name {
            font-size: 20px;
            font-weight: 700;
          }

          .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    &.fw_box3 {
      .sub_title {
        margin-top: 16px;
        font-size: 18px;
        text-align: center;
      }

      .list_wrap {
        padding: 0 15%;
        margin-top: 40px;

        .zf_title {
          background: url("@/assets/yx_study/new/zf1.png");
          background-size: 100% 100%;
          font-size: 18px;
          font-weight: 700;
          height: 56px;
          line-height: 56px;
          text-align: center;
        }

        &.list_wrap1 {
          .zf_list {
            margin-top: 8px;

            .item_box {
              background: #f0f4f8;
              border-radius: 8px;
              padding: 12px;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 10px;

              .item {
                width: calc((100% - 12px) / 2);
                display: flex;
                align-items: center;
                font-size: 14px;
                background: #fff;
                padding: 10px 14px;
                box-sizing: border-box;
                border-radius: 4px;
                line-height: 24px;

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 8px;
                }
              }
            }
          }
        }

        &.list_wrap2 {
          .zf_title {
            color: #1285ff;
          }

          .zf_list {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 10px;

            .list_box {
              width: calc((100% - 12px * 2) / 3);
              display: flex;
              flex-direction: column;
              font-size: 14px;

              .name {
                background: url("@/assets/yx_study/new/zf3.png");
                background-size: 100% 100%;
                height: 56px;
                line-height: 56px;
                text-align: center;
                // font-size: 18px;
                font-weight: 700;
              }

              .item_box {
                flex-grow: 1;
                margin-top: 8px;
                background: #f0f4f8;
                border-radius: 8px;
                padding: 12px;
                display: flex;
                flex-direction: column;

                .item {
                  flex-grow: 1;
                  display: flex;
                  align-items: center;
                  // font-size: 14px;
                  background: #fff;
                  margin-top: 10px;
                  padding: 10px 14px;
                  border-radius: 4px;
                  line-height: 24px;

                  &:first-child {
                    margin-top: 0;
                  }

                  img {
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 60px;
    display: flex;
    justify-content: center;

    .btn {
      background: linear-gradient(92deg, #1285ff 0%, #2cdbff 100%);
      border-radius: 6px;
      padding: 10px 20px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>