let productList = [
    // {
    //   title: "恒牛-步步为赢",
    //   type: "product1",
    //   aos: "fade-right",
    //   tag_text: "入门首选",
    //   tag: require("@/assets/index/product/tag1.png"),
    //   prices: "29800元/6个月",
    //   list: [
    //     {
    //       name: "交易日陪伴",
    //       text: "股票交流圈：早盘解读、午盘点评、收盘总结、问题解答",
    //     },
    //     {
    //       name: "强势股票池",
    //       text: "每周精选教案标的：符合选股战法，精选股票标的",
    //     },
    //     {
    //       name: "选股课程",
    //       text: "每周2节内部选股逻辑课：贴合当下盘面及热点选出符合战法的股票",
    //     },
    //     {
    //       name: "教学课程",
    //       text: "股市教学课程：由浅入深精品课程，打造操盘手思维",
    //     },
    //     {
    //       name: "精品特刊",
    //       text: "消息面解读：把握当下热点板块，精选板块热股",
    //     },
    //   ],
    // },
    {
        title: "智汇操盘",
        type: "product1",
        aos: "fade-right",
        tag_text: "赢战2025首选",
        tag: require("@/assets/index/product/tag1.png"),
        prices: "29800元/6个月",
        list: [
            {
                name: "智汇交流圈",
                text: "零距离与机构互动：实时盘面分析、投资互动答疑，助您紧跟市场节奏",
            },
            {
                name: "精选股票池",
                text: "战法优选组合：严筛优质标的，中短线兼具，精准匹配当前行情",
            },
            {
                name: "实战选股课堂",
                text: "每周双师直播：双课教授选股逻辑与战法技巧，配置课件与回放，随心学",
            },
            {
                name: "系统投资教学",
                text: "65节阶梯式课程：从基础到高阶，系统塑造专属专业操盘手体系",
            },
            {
                name: "热点特刊锦囊",
                text: "聚焦风口板块：深度解析政策与行业动向，精选潜力股，把握投资先机",
            },
        ],
    },
    {
        title: "恒牛私享家",
        type: "product2",
        aos: "fade-left",
        tag_text: "VIP私享",
        tag: require("@/assets/index/product/tag2.png"),
        prices: "",
        list: [
            {
                name: "上市公司研学社",
                text: "走进了上市公司：获得机会参与上市公司走访，设身处地了解持股情况",
            },
            {
                name: "私享策略池",
                text: "私人定制：每位用户可获得量身定制的服务方案，符合自身操作特性",
            },
            {
                name: "vip策略早鸟报",
                text: "特刊分析：宏观方向把控，周行情回顾",
            },
            {
                name: "实战私享课",
                text: "服务课程：盘中服务课，实战选股课，跟踪盘面情况",
            },
            {
                name: "益学大咖课程",
                text: "益学大咖指导：对盘面和复盘的指导。对市场的点评，学习选股学习方法",
            },
        ],
    },
]

export default productList