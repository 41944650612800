<template>
  <div class="serve1_wrap">
    <div class="serve1_box">
      <div class="left_box">
        <div class="title_box">
          <div class="title" data-aos="zoom-in">智能化投研服务</div>

          <div class="sub_title" data-aos="fade-up" data-aos-easing="ease">
            益/学/堂/构/建/投/资/者/教/育/体/系
          </div>
        </div>

        <div class="serve_text" data-aos="fade-up" data-aos-delay="400">
          <div class="text">
            我们的投资者教育体系以 "全流程智能协同"
            为核心理念，各层级紧密协作，为用户带来卓越体验。根基层汇聚多元数据，运用前沿技术为投资决策提供精准依据；技术中台层采用分布式架构，具备强大处理能力，保障策略运行高效运作。
          </div>

          <div class="text">
            平台依托自主研发的
            技术指标模型、PB级大数据处理能力、等技术亮点，让投资者教育业务能在各场景中稳定开展，实现智能化决策与全流程高效协同。
          </div>

          <div class="text">
            益学通过等保三级认证，获得近百项软著成果，保证系统的稳健运行。
          </div>
        </div>
      </div>

      <div class="right_box">
        <img
          src="@/assets/index/serve1/1.png"
          data-aos="zoom-in"
          data-aos-delay="400"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.serve1_wrap {
  height: 100%;
  background: url("@/assets/index/serve1/bg.png") no-repeat;
  background-size: cover;
  // padding: 100px 10% 0;
  padding: 0 10%;
  display: flex;
  align-items: center;

  .serve1_box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_box {
      width: 40%;

      .title_box {
        .title {
          display: inline-block;
          font-size: 40px;
          color: #5dacff;
        }

        .sub_title {
          margin-top: 16px;
          color: #fff;
          font-size: 18px;
        }
      }

      .serve_text {
        margin-top: 100px;
        color: #fff;
        text-align: justify;

        .text {
          margin-top: 36px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .right_box {
      width: 48%;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

@media (min-width: 1921px) {
  .serve1_wrap {
    .serve1_box {
      .left_box {
        .serve_text {
          margin-top: 120px;

          .text {
            margin-top: 42px;
          }
        }
      }
    }
  }
}
</style>