<template>
  <div class="teacher_wrap">
    <div class="title_box">
      <div class="title" data-aos="zoom-in">专业团队</div>

      <div class="sub_title" data-aos="fade-up" data-aos-easing="ease">
        为/您/的/投/资/策/略/保/驾/护/航
      </div>
    </div>

    <div class="teacher_info_box">
      <div class="info_box">
        <!-- <img class="info_bg" src="@/assets/index/teacher/bg1.png" /> -->

        <div
          class="info_list"
          data-aos="fade-down"
          data-aos-delay="1500"
          data-aos-duration="1500"
        >
          <div
            class="info_item_box"
            :class="{ info_item_box_active: activeId == item.id }"
            v-for="(item, index) in teacherList"
            :key="index"
          >
            <div class="info_item" v-if="activeId == item.id">
              <div class="intro_box">
                <div class="name_box">
                  <div class="name">{{ item.name }}</div>
                  {{ item.title2 }}
                </div>

                <div class="intro1">{{ item.intro1 }}</div>

                <div class="intro2">{{ item.intro2 }}</div>
              </div>

              <img class="teacher" :src="item.img2" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="teacher_list">
      <div
        class="teacher_item"
        v-for="(item, index) in teacherList"
        :key="index"
        @mouseenter="teacherClick(item.id)"
        data-aos="fade-down-left"
        :data-aos-delay="400 + 200 * index"
        data-aos-duration="1500"
      >
        <img
          class="light"
          :src="
            activeId == item.id
              ? require('@/assets/index/teacher/light_after_active.png')
              : require('@/assets/index/teacher/light_after.png')
          "
        />

        <img class="teacher" :src="item.img1" />

        <img
          class="light"
          :src="
            activeId == item.id
              ? require('@/assets/index/teacher/light_before_active.png')
              : require('@/assets/index/teacher/light_before.png')
          "
        />

        <div class="name_box">
          <div class="name">{{ item.name }}</div>
          <div class="title">{{ item.title1 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teacherList from "@/utils/teacherList";

export default {
  data() {
    return {
      activeId: 2,
      teacherList: teacherList,
    };
  },
  mounted() {},
  methods: {
    // 老师 click
    teacherClick(id) {
      setTimeout(() => {
        this.activeId = id;
      }, 300);
    },
  },
};
</script>

<style lang="less" scoped>
.teacher_wrap {
  height: 100%;
  background: url("@/assets/index/teacher/bg.png") no-repeat;
  background-size: cover;

  .title_box {
    padding-top: 100px;
    padding-left: 10%;

    .title {
      display: inline-block;
      font-size: 40px;
      color: #5dacff;
    }

    .sub_title {
      margin-top: 16px;
      color: #fff;
      font-size: 18px;
    }
  }

  .teacher_info_box {
    width: 100%;
    height: 40%;
    background: url("@/assets/index/teacher/bg1.png") no-repeat;
    background-size: 100%;
    background-position: bottom;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);

    .info_box {
      position: relative;
      height: 100%;

      .info_bg {
        width: 100%;
        display: block;
      }

      .info_list {
        width: 100%;
        padding: 0 15%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;

        .info_item_box {
          transition: opacity 2s ease, transform 2s ease;
          opacity: 0;

          &.info_item_box_active {
            opacity: 1;
          }

          .info_item {
            display: flex;
            // align-items: center;
            justify-content: center;

            .intro_box {
              width: 50%;
              padding-top: 15%;
              margin-right: 7%;
              color: #fff;
              font-size: 16px;

              .name_box {
                display: flex;
                align-items: flex-end;

                .name {
                  font-size: 30px;
                  font-weight: bold;
                  margin-right: 24px;
                }
              }

              .intro1 {
                margin-top: 16px;
              }

              .intro2 {
                margin-top: 26px;
                text-align: justify;
              }
            }

            .teacher {
              width: 30%;
            }
          }
        }
      }
    }
  }

  .teacher_list {
    width: 100%;
    height: 28%;
    box-sizing: border-box;
    padding: 0 10%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;

    .teacher_item {
      width: 20%;
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
      }

      .name_box {
        color: #fff;
        position: absolute;
        top: 22%;
        right: 22%;
        display: flex;

        div {
          writing-mode: vertical-lr;
        }

        .name {
          font-size: 14px;
          font-weight: 700;
          margin-right: 4px;
        }

        .title {
          font-size: 12px;
        }
      }
    }
  }
}
</style>