let teacherList = [
    // {
    //   name: "邹海洋",
    //   title1: "益学资深导师",
    //   title2: "益学资深导师",
    //   intro1: "执业证书编号：A1220623080003",
    //   intro2:
    //     "深耕专业，持投顾基金期权等多重执照，拥有多家知名机构就职经验，擅长多种指标工具组合把控情绪节奏追踪热点，倾力加码挖掘机会，教你做更主流的交易！",
    //   img1: require("@/assets/index/teacher/zhy1.png"),
    //   img2: require("@/assets/index/teacher/zhy2.png"),
    // },
    {
        id: 1,
        name: "李正闯",
        title1: "益学资深导师",
        title2: "益学资深导师",
        intro1: "执业证书编号：A1220624080002",
        intro2:
            "行业经验21年，经历几轮市场牛熊，曾任职多家投资机构的投资顾问，受邀做过湖北卫视和浙江经广等媒体的节目嘉宾。独创“牛回头战法”、“首板战法”的选股逻辑以及量价关系的择时体系，熊市短逻辑，牛市抓波段，希望帮助更多投资者从稳健的投资思路中获取利益最大化。",
        img1: require("@/assets/index/teacher/lzc1.png"),
        img2: require("@/assets/index/teacher/lzc2.png"),
        comName: 'lzcPage',
    },
    {
        id: 2,
        name: "杨凯",
        title1: "益学资深导师",
        title2: "益学资深导师",
        intro1: "执业证书编号：A1220616030001",
        intro2:
            "超15年股海征战之旅的匠心导师，拥有上海交通大学&瑞士中央管理大学MBA双造诣背景，曾任中央人民广播电台《经济之声》以及第一财经等等主流官方平台特邀嘉宾，历经牛熊洞察敏锐深谙机构操盘体系，还精通期货等多维市场组合投资，开班带队多年出手快准稳，为你做看得懂的交易！",
        img1: require("@/assets/index/teacher/yk1.png"),
        img2: require("@/assets/index/teacher/yk2.png"),
        comName: 'ykPage',
    },
    {
        id: 3,
        name: "曾海龙",
        title1: "益学资深导师",
        title2: "益学资深导师",
        intro1: "执业证书编号：A1220619030001",
        intro2:
            "前私募基金经理、职业投资人，上交所首批期权策略顾问，曾任上海第一财经等长期特邀嘉宾。擅长投资者心理情绪分析，立足企业价值成长和市场人性博弈，道与术的融合，突出机构视角技巧看盘识强弱，个股强弱辨机会，逻辑研判定买点，走势跟踪找卖点,行情大势锁仓位，人性心态稳情绪。",
        img1: require("@/assets/index/teacher/zhl1.png"),
        img2: require("@/assets/index/teacher/zhl2.png"),
        comName: 'zhlPage',
    },
    {
        id: 4,
        name: "张帅",
        title1: "益学资深导师",
        title2: "益学资深导师",
        intro1: "执业证书编号：A1220619040003",
        intro2:
            "创新研究宏观政策题材并深入微观动态跟踪，具有超强硬核逻辑与前沿眼光并且独创了六势操盘战法，第一财经未来理财师特邀评委导师是浙江等多个知名经济电台论坛特邀嘉宾，打法惊奇出其不意，让你做更科学的交易！",
        img1: require("@/assets/index/teacher/zs1.png"),
        img2: require("@/assets/index/teacher/zs2.png"),
        comName: 'zsPage',
    },
]

export default teacherList