<template>
  <div class="yx_study_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/yxStudy'" />

    <div class="teacher_info_wrap">
      <div class="teacher_list">
        <div
          class="teacher_item"
          v-for="(item, index) in teacherList"
          :key="index"
          @mouseenter="teacherClick(item.id)"
        >
          <img
            class="light1"
            :src="
              activeId == item.id
                ? require('@/assets/index/teacher/light_after_active.png')
                : require('@/assets/index/teacher/light_after.png')
            "
          />

          <img class="teacher" :src="item.img1" />

          <img
            class="light2"
            :src="
              activeId == item.id
                ? require('@/assets/index/teacher/light_before_active.png')
                : require('@/assets/index/teacher/light_before.png')
            "
          />

          <div class="name_box">
            <div class="name">{{ item.name }}</div>
            <div class="title">{{ item.title1 }}</div>
          </div>
        </div>
      </div>

      <div class="content_wrap">
        <component :is="teacherItem.comName" :teacherItem="teacherItem" />
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import teacherList from "@/utils/teacherList";

import lzcPage from "./teacher/lzc.vue"; // 李正闯
import ykPage from "./teacher/yk.vue"; // 杨凯
import zhlPage from "./teacher/zhl.vue"; // 曾海龙
import zsPage from "./teacher/zs.vue"; // 张帅

import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      activeId: 2,
      teacherList: teacherList,
    };
  },
  computed: {
    teacherItem() {
      return this.teacherList.find((item) => item.id === this.activeId);
    },
  },
  components: {
    lzcPage,
    ykPage,
    zhlPage,
    zsPage,
    navbar_,
    footer_,
  },
  created() {},
  mounted() {},
  methods: {
    // 老师 click
    teacherClick(id) {
      setTimeout(() => {
        this.activeId = id;
      }, 300);
    },
  },
};
</script>

<style lang="less" scoped>
.yx_study_wrap {
  .teacher_info_wrap {
    .teacher_list {
      padding: 100px 10% 0;
      background: url("@/assets/yx_study/new/bg_top.png") no-repeat;
      background-size: cover;
      display: flex;
      justify-content: space-around;

      .teacher_item {
        width: 16%;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
          display: block;

          &.teacher,
          &.light2 {
            position: absolute;
            top: 0;
          }
        }

        .name_box {
          position: absolute;
          top: 22%;
          right: 22%;
          display: flex;

          div {
            writing-mode: vertical-lr;
          }

          .name {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
          }

          .title {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>