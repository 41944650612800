<template>
  <div class="serve2_wrap">
    <div class="serve2_box">
      <div class="serve_list" data-aos="fade-right">
        <div class="item_left">
          <span>投教知识全媒体传播</span>
        </div>

        <div class="item_right item_right1">
          <div
            class="media_list"
            v-for="(item, index) in mediaList"
            :key="index"
          >
            <img :src="item.icon" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>

      <div class="serve_list" data-aos="fade-left" data-aos-delay="100">
        <div class="item_left">
          <span>循环质检</span>
        </div>

        <div class="item_right item_right2">
          <div class="xhzj_box xhzj_box1">
            <div class="list item_bg">深度学习AI模型</div>

            <div class="list item_bg">审核策略优化</div>
          </div>

          <div class="xhzj_box xhzj_box2">
            <div class="list list1">
              <div class="item item_bg">文本语义分析</div>
              <div class="item item_bg">图像识别分析</div>
              <div class="item item_bg">视频内容分析</div>
            </div>

            <div class="list list2">
              <div class="item item_bg">数据分析和挖掘</div>
              <div class="item item_bg">模型优化</div>
            </div>
          </div>
        </div>
      </div>

      <div class="serve_list" data-aos="fade-right" data-aos-delay="200">
        <div class="item_left">
          <span>CRM</span>

          <span>益学金融研究苑</span>
        </div>

        <div class="item_right item_right3">
          <div class="list list1">
            <div class="item item_bg">数据驱动客户服务体系</div>
            <div class="item item_bg">深度客户分析</div>
            <div class="item item_bg">客户个性化推荐算法</div>
          </div>

          <div class="list list2">
            <div class="item item_bg">专业团队</div>
            <div class="item item_bg">投研能力</div>
            <div class="item item_bg">解决方案</div>
            <div class="item item_bg">教研体系</div>
            <div class="item item_bg">数据驱动</div>
          </div>
        </div>
      </div>

      <div class="serve_list" data-aos="fade-left" data-aos-delay="300">
        <div class="item_left">
          <span>底层支撑</span>
        </div>

        <div class="item_right item_right4">
          <div class="dczc_box dczc_box1">
            <div class="list list1 item_bg">CMS</div>

            <div class="list list2 item_bg">策略中心</div>
          </div>

          <div class="dczc_box dczc_box2">
            <div class="list list1">
              <div class="item item_bg">金融知识图谱</div>
              <div class="item item_bg">知识库学习更新</div>
            </div>

            <div class="list list2">
              <div class="item item_bg">趋势多空，机构资金线等指标库</div>
              <div class="item item_bg">市场热点舆情收集并分析</div>
              <div class="item item_bg">热股模型创建</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaList: [
        {
          name: "企业微信",
          icon: require("@/assets/index/serve2/icon1.png"),
        },
        {
          name: "公众号",
          icon: require("@/assets/index/serve2/icon2.png"),
        },
        {
          name: "视频号",
          icon: require("@/assets/index/serve2/icon3.png"),
        },
        {
          name: "抖音",
          icon: require("@/assets/index/serve2/icon4.png"),
        },
        {
          name: "快手",
          icon: require("@/assets/index/serve2/icon5.png"),
        },
        {
          name: "小红书",
          icon: require("@/assets/index/serve2/icon6.png"),
        },
        {
          name: "门户网站",
          icon: require("@/assets/index/serve2/icon7.png"),
        },
        {
          name: "益学堂APP",
          icon: require("@/assets/index/serve2/icon8.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.serve2_wrap {
  height: 100%;
  background: url("@/assets/index/serve1/bg.png") no-repeat;
  background-size: cover;
  // padding: 100px 10% 0;
  padding: 0 16%;
  display: flex;
  align-items: center;

  .serve2_box {
    width: 100%;
    color: #fff;

    .serve_list {
      margin-top: 30px;
      min-height: 132px;
      padding: 16px 24px 16px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      background: url("@/assets/index/serve2/list_bg1.png") no-repeat;
      background-size: 100% 100%;

      // background: url("@/assets/index/serve2/list_bg.png");
      // background-size: 400% 100%; /* 横向4帧，每帧占25% */
      // background-position: 0 0; /* 初始位置 */
      // animation: move 1.5s steps(4) infinite;
      // @keyframes move {
      //   to {
      //     background-position: -400% 0;
      //   }
      // }

      &:first-child {
        margin-top: 0;
      }

      .item_left {
        min-width: 238px;
        font-size: 20px;
        font-weight: 700;
        border-right: 1px solid #0f517e;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }

      .item_right {
        width: calc(100% - 238px);
        padding-left: 24px;
        box-sizing: border-box;
        font-size: 12px;

        .item_bg {
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(152, 203, 255, 0.2) 100%
          );
          border-radius: 10px;
          overflow: hidden;
        }

        &.item_right1 {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .media_list {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 40px;
              height: 40px;
              display: block;
              margin-bottom: 10px;
            }

            span {
              // font-size: 12px;
            }
          }
        }

        &.item_right2 {
          .xhzj_box {
            display: flex;
            justify-content: space-between;

            &.xhzj_box1 {
              .list {
                width: calc((100% - 10px) / 2);
                // height: 44px;
                // line-height: 44px;
                // text-align: center;
                // background: linear-gradient(
                //   0deg,
                //   rgba(0, 0, 0, 0) 0%,
                //   rgba(152, 203, 255, 0.2) 100%
                // );
                // border-radius: 10px;
              }
            }

            &.xhzj_box2 {
              margin-top: 12px;

              .list {
                width: calc((100% - 10px) / 2);
                display: flex;
                justify-content: space-between;

                .item {
                  // height: 44px;
                  // line-height: 44px;
                  // text-align: center;
                  // background: linear-gradient(
                  //   0deg,
                  //   rgba(0, 0, 0, 0) 0%,
                  //   rgba(152, 203, 255, 0.2) 100%
                  // );
                  // border-radius: 10px;
                }

                &.list1 {
                  .item {
                    width: calc((100% - 20px) / 3);
                  }
                }

                &.list2 {
                  .item {
                    width: calc((100% - 10px) / 2);
                  }
                }
              }
            }
          }
        }

        &.item_right3 {
          .list {
            display: flex;
            justify-content: space-between;

            .item {
              // height: 44px;
              // line-height: 44px;
              // text-align: center;
              // background: linear-gradient(
              //   0deg,
              //   rgba(0, 0, 0, 0) 0%,
              //   rgba(152, 203, 255, 0.2) 100%
              // );
              // border-radius: 10px;
            }

            &.list1 {
              .item {
                width: calc((100% - 20px) / 3);
              }
            }

            &.list2 {
              margin-top: 12px;

              .item {
                width: calc((100% - 40px) / 5);
              }
            }
          }
        }

        &.item_right4 {
          .dczc_box {
            display: flex;
            justify-content: space-between;

            &.dczc_box1 {
              .list {
                height: 44px;
                // line-height: 44px;
                // text-align: center;
                // background: linear-gradient(
                //   0deg,
                //   rgba(0, 0, 0, 0) 0%,
                //   rgba(152, 203, 255, 0.2) 100%
                // );
                // border-radius: 10px;

                &.list1 {
                  width: calc((100% - 10px) / 3);
                }

                &.list2 {
                  width: calc((100% - 10px) / 3 * 2);
                }
              }
            }

            &.dczc_box2 {
              margin-top: 12px;

              .list {
                display: flex;
                justify-content: space-between;

                .item {
                  // height: 44px;
                  // line-height: 44px;
                  // text-align: center;
                  // background: linear-gradient(
                  //   0deg,
                  //   rgba(0, 0, 0, 0) 0%,
                  //   rgba(152, 203, 255, 0.2) 100%
                  // );
                  // border-radius: 10px;
                }

                &.list1 {
                  width: calc((100% - 10px) / 3);

                  .item {
                    width: calc((100% - 10px) / 2);
                  }
                }

                &.list2 {
                  width: calc((100% - 10px) / 3 * 2);

                  .item {
                    width: calc((100% - 20px) / 3);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .serve2_wrap {
    .serve2_box {
      .serve_list {
        margin-top: 48px;
        min-height: 156px;
        padding: 28px 24px 28px 0;
      }
    }
  }
}
</style>